import React, { useState, useEffect } from 'react';

import {
  Box,
  Container,
  // Grid,
  makeStyles,
  // List,
  // ListItem
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  // IconButton,
  // Button
} from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
// import DeleteIcon from '@material-ui/icons/Delete';
import MenuComponent from 'src/views/products/ProductListView/MenuComponent';
import Page from 'src/components/Page';
// import getCategories from 'src/views/productss/CategoryListView/getCategories';

import Toolbar from './Toolbar';
import EditProductDialog from './EditProductDialog';
import firebase from '../../../utils/firebase';
import ConfirmDialog from '../../../components/ConfirmDialog';

// import ProductCard from './ProductCard';
// import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState();
  const [filterby, setFilterBy] = useState('product');
  const [filtered, setFiltered] = useState([]);
  const [showEditProduct, setShowEditProduct] = useState(false);
  const [productEdit, setProductEdit] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [idToDelete, setIdToDelete] = useState();

  window.filtered = filtered;
  const confirmDelete = (id) => {
    setIdToDelete(id);
    setConfirmMessage(`Are you sure you want to delete ${id}`);
    setShowConfirm(true);
  };
  const doDelete = () => {
    firebase.firestore().collection('products').doc(idToDelete).delete();
    setShowConfirm(false);
  };

  useEffect(() => {
    let _products = products;
    if (filter) {
      _products = _products.filter(product => {
        let isValid = false;
        filter.split(' ').forEach(singleFilter => {
          if (!singleFilter) return;
          if (filterby === 'product') {
            if (product._name.includes(singleFilter.toLowerCase())) isValid = true;
          } else if (filterby === 'sku') {
            if (product.sku && product.sku.toLowerCase().includes(singleFilter.toLowerCase())) isValid = true;
          } else if (filterby === 'variant') {
            if (product.variant && product.variant.toLowerCase().includes(singleFilter.toLowerCase())) isValid = true;
          } else if (filterby === 'carton') {
            if (product.cartonNumber === parseInt(singleFilter, 10)) isValid = true;
          }
          // if (product._name.includes(singleFilter.toLowerCase())) isValid = true;
          // if (product.color && product.color.toLowerCase().includes(singleFilter.toLowerCase())) isValid = true;
        });
        return isValid;
      });
    }
    setFiltered(_products.slice(0, 20));
  }, [products, filter, filterby]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('products').onSnapshot((snap) => {
      const _products = snap.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setProducts(_products);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Page
      className={classes.root}
      title="Inventory - Categories"
    >
      <Container maxWidth={false}>
        <Toolbar setFilter={setFilter} setFilterBy={setFilterBy} />
        <EditProductDialog show={showEditProduct} onClose={() => setShowEditProduct(false)} productEdit={productEdit} />
        <ConfirmDialog open={showConfirm} title="Confirm Delete" message={confirmMessage} onClose={() => setShowConfirm(false)} onConfirm={doDelete} />
        <Box mt={3}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Products</TableCell>
                  <TableCell>Variant</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Carton #</TableCell>
                  <TableCell align="right">Stocks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtered && filtered.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.sku}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.variant}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.size}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      ₱{row.price}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.category}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.cartonNumber}
                    </TableCell>
                    <TableCell component="th" align="right" scope="row">
                      {row.stocks}
                    </TableCell>
                    <TableCell align="right">
                      <MenuComponent onDelete={() => confirmDelete(row.id)} productInfo={row} setShowEditProduct={setShowEditProduct} setProductEdit={setProductEdit} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box> */}
      </Container>
    </Page>
  );
};

export default ProductList;
