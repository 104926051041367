import React, {useEffect, useState} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
// import Budget from './Budget';
import { pesoSymbol } from 'src/views/constants';
import SalesToday from './SalesToday';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Sales from './Sales';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import TotalProfit from './TotalProfit';
import TrafficByDevice from './TrafficByDevice';
import firebase from '../../../utils/firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [salesToday, setSalesToday] = useState('');
  const [discountsToday, setDiscountsToday] = useState('');
  const [totalCustomers, setTotalCustomers] = useState('');

  useEffect(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const unsubcribe = firebase.firestore().collection('sales').where('date', '>=', now)
      .onSnapshot(snap => {
        let totalToday = 0;
        let discountToday = 0;
        snap.docs.forEach(doc => {
          const data = doc.data();
          totalToday += data.total - data.discount;
          discountToday += data.discount;
        });
        if (totalToday) {
          setSalesToday(`${pesoSymbol}${totalToday}`);
        } else {
          setSalesToday('None');
        }
        if (discountToday) {
          setDiscountsToday(`-${pesoSymbol}${discountToday}`);
        } else {
          setDiscountsToday('None');
        }
        if (snap.docs.length) {
          setTotalCustomers(snap.docs.length.toString());
        } else {
          setTotalCustomers('None');
        }
      });
    return () => unsubcribe();
  }, []);

  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <SalesToday salesToday={salesToday} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit discountsToday={discountsToday} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers totalCustomers={totalCustomers} />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TasksProgress />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
