// Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from 'firebase/app';
import firebase from 'firebase';

// import 'firebase/auth';
import 'firebase/firestore';
// import 'firebase/functions';
// import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyBDPr17OpBsot7hOSpnLpgBCFYzjsVTs1k',
  authDomain: 'nbapparel-9704e.firebaseapp.com',
  projectId: 'nbapparel-9704e',
  storageBucket: 'nbapparel-9704e.appspot.com',
  messagingSenderId: '59082975042',
  appId: '1:59082975042:web:539f9ffa9b3d8d42b30adb',
  measurementId: 'G-F139T6N9SP'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.functions().useFunctionsEmulator('http://localhost:5001');
firebase.firestore().enablePersistence()
  .catch((err) => {
    console.warn('Firestore offline persistence failed.', err);
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

// exports.firebase = firebase;
export default firebase;
