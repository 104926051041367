import firebase from '../../../utils/firebase';
import verifyUniqueSku from './verifyUniqueSku';

function hasMissingHeader(aHeaders) {
  const requirements = ['name', 'size', 'price', 'stocks'];
  const validHeaders = [...requirements, 'variant'];
  console.log('req', requirements);
  console.log('validHeaders', validHeaders);

  // aHeaders = aHeaders.map(x => x.toLowerCase());

  for (const requiredHeader of requirements) {
    if (!aHeaders.includes(requiredHeader)) return requiredHeader;
  }
  return false;
}

async function addProduct(batch, oInput) {
  if (!oInput.name) {
    console.log('name is empty', oInput);
    return '';
  }
  if (!oInput.variant) {
    console.log('variant is empty', oInput);
    return '';
  }
  const _productName = oInput.name.toLowerCase().trim();
  oInput._name = _productName;
  console.log('Adding', oInput);
  // Get a new write batch

  // Set the value of 'NYC'
  if (oInput.sku) {
    const productRef = firebase.firestore().collection('products').doc(oInput.sku);
    batch.set(productRef, oInput, { merge: true });
  }
}

async function addCategory(categoryName) {
  const _categoryName = categoryName.toLowerCase().trim();
  return firebase.firestore().collection('categories').where('_name', '==', _categoryName).get()
    .then(snap => {
      if (snap.empty) {
        return firebase.firestore().collection('categories').add({ name: categoryName, _name: _categoryName }, { merge: true });
      }
    });
}

async function uploadCategories(aCategories, setUploadProgress) {
  if (!Array.isArray(aCategories)) return;
  console.log('Uploading categories.');

  const _uniqueCategories = [...new Set(aCategories)];
  let index = 0;
  let pct = 0;
  for (const categoryName of _uniqueCategories) {
    await addCategory(categoryName);
    index++;
    pct = (index / _uniqueCategories.length) * 100;
    setUploadProgress(pct);
  }
  setUploadProgress(100);
  console.log('Done uploading categories...');
}

export default async function importProducts(aRows, setUploadProgress) {
  const aHeaders = aRows[0].map(x => x.toLowerCase());
  const missingHeader = hasMissingHeader(aHeaders);
  if (missingHeader) {
    console.log('Missing required header.', missingHeader);
    alert(`Missing required header: ${missingHeader}`);
    return;
  }

  const idxSku = aHeaders.indexOf('sku');
  const idxName = aHeaders.indexOf('name');
  const idxVariant = aHeaders.indexOf('variant');
  const idxSize = aHeaders.indexOf('size');
  const idxPrice = aHeaders.indexOf('price');
  const idxStocks = aHeaders.indexOf('stocks');
  const idxCategory = aHeaders.indexOf('category');
  const idxCarton = aHeaders.indexOf('carton #');

  const aAlreadyExists = await verifyUniqueSku(aRows, idxSku);
  if (aAlreadyExists.length) {
    return {
      error: 'Duplicate SKUs found',
      message: 'Duplicate SKUs were found. Import feature must only be used for new products.',
      products: aAlreadyExists
    };
  }

  console.log('total to add', aRows.length);

  const categories = [];

  let batch = firebase.firestore().batch();
  let pct = 0;
  let batched = 0;
  console.log('total products to add', aRows.length);
  for (const index in aRows) {
    if (index > 0) {
      const row = aRows[index];
      const sku = row[idxSku];
      const name = row[idxName];
      const variant = row[idxVariant];
      const size = row[idxSize] || '';
      const price = row[idxPrice] || 0;
      const stocks = row[idxStocks] || 0;
      const category = row[idxCategory] || '';
      const cartonNumber = row[idxCarton] || '';
      if (category && !categories.includes(category)) {
        categories.push(category);
      }
      console.log('name', name, variant, size, price, stocks);
      await addProduct(batch, {
        sku, name, variant, size, price, stocks, category, cartonNumber
      });
      batched += 1;

      pct = (index / aRows.length) * 100;
      setUploadProgress(pct);

      if (batched > 498) {
        console.log('Committing batch.');
        batched = 0;
        await batch.commit().then(() => {
          console.log('Batch has been succesfuly committed.');
        });
        batch = firebase.firestore().batch();
      }
    }
  }
  if (batched > 0) {
    console.log('Committing last batch.');
    await batch.commit();
    console.log('Done!');
  }
  setUploadProgress(100);

  uploadCategories(categories, setUploadProgress);
}
