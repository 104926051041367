import React, { useState, useEffect } from 'react';

import {
  Box,
  Container,
  // Grid,
  makeStyles,
  // List,
  // ListItem
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  // IconButton,
  // Button
} from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
// import DeleteIcon from '@material-ui/icons/Delete';
import MenuComponent from 'src/views/categories/CategoryListView/MenuComponent';
import Page from 'src/components/Page';
// import getCategories from 'src/views/categories/CategoryListView/getCategories';

import Toolbar from './Toolbar';
import EditCategoryDialog from './EditCategoryDialog';
import firebase from '../../../utils/firebase';

// import ProductCard from './ProductCard';
// import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const CategoryList = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [showEditCategory, setShowEditCategory] = useState(false);
  const [categoryEdit, setCategoryEdit] = useState({});

  const onDelete = (id) => {
    firebase.firestore().collection('categories').doc(id).delete();
  };

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('categories').onSnapshot((snap) => {
      const _categories = snap.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setCategories(_categories);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Page
      className={classes.root}
      title="Inventory - Categories"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <EditCategoryDialog show={showEditCategory} onClose={() => setShowEditCategory(false)} categoryEdit={categoryEdit} />
        <Box mt={3}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Category Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories && categories.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <MenuComponent onDelete={() => onDelete(row.id)} categoryInfo={row} setShowEditCategory={setShowEditCategory} setCategoryEdit={setCategoryEdit} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box> */}
      </Container>
    </Page>
  );
};

export default CategoryList;
