import firebase from '../../../utils/firebase';

export default async function verifyUniqueSku(aRows, skuIdx) {
  const nonUniques = [];
  const skus = aRows.map(row => row[skuIdx]);
  console.log('skus', skus);

  const {existingSkus, oSku} = await firebase.firestore().collection('products').get()
    .then(snap => {
      const docs = snap.docs;
      const _oSku = {};
      const _existingSkus = docs.map(x => x.id);
      docs.forEach(doc => {
        _oSku[doc.id] = doc.data();
      });
      return {existingSkus: _existingSkus, oSku: _oSku};
    });

  skus.forEach(sku => {
    if (existingSkus.includes(sku)) {
      nonUniques.push({
        sku,
        name: oSku[sku].name,
        variant: oSku[sku].variant,
        size: oSku[sku].size
      });
    }
  });
  console.log('nonUniques', nonUniques);
  return nonUniques;
}