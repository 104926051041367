import React, {
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import {
  // Box,
  // Card,
  CardContent,
  // CardHeader,
  // Divider,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel
  // makeStyles
} from '@material-ui/core';
import firebase from '../../../utils/firebase';

// const suppliers = [
//   {
//     value: 'supplier-1',
//     label: 'Supplier 1'
//   },
//   {
//     value: 'supplier-2',
//     label: 'Supplier 2'
//   },
//   {
//     value: 'supplier-3',
//     label: 'Supplier 3'
//   }
// ];

const EditProductDialog = ({ show, onClose, productEdit }) => {
  const [open, setOpen] = useState(false);
  const [productName, setProductName] = useState(productEdit.name);
  const [stocks, setStocks] = useState(productEdit.stocks);
  const [price, setPrice] = useState(productEdit.price);
  const [category, setCategory] = useState(productEdit.category || '');
  const [showError, setShowError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [sku, setSku] = useState(productEdit.sku || '');
  const [variant, setVariant] = useState(productEdit.variant || '');
  const [size, setSize] = useState(productEdit.size || '');
  const [cartonNumber, setCartonNumber] = useState(productEdit.cartonNumber || '');

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    console.log('category', category);
  }, [category]);

  useEffect(() => {
    console.log('productEdit', productEdit);
    setProductName(productEdit.name || '');
    setStocks(productEdit.stocks || 0);
    setPrice(productEdit.price || '');
    setCategory(productEdit.category || '');
    setSku(productEdit.sku || '');
    setVariant(productEdit.variant || '');
    setSize(productEdit.size || '');
    setCartonNumber(productEdit.cartonNumber || '');
  }, [productEdit]);

  const handleAddProduct = () => {
    const name = productName || productEdit.name;
    if (name) {
      let _stocks = stocks;
      if (!_stocks) {
        _stocks = 0;
      }
      if (!price) {
        setShowError('Price cannot be empty.');
        return;
      }
      if (name) {
        const _productName = name.toLowerCase().trim();
        firebase.firestore().collection('products').doc(productEdit.id).set({
          name,
          _name: _productName,
          stocks: _stocks,
          price: price || '',
          category: category || '',
          sku: sku || '',
          variant,
          size,
          cartonNumber
        }, { merge: true });
        setOpen(false);
        onClose();
      }
    }
  };

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  useEffect(() => {
    const unsubscribe = firebase.firestore().collection('categories').onSnapshot((snap) => {
      const _categories = snap.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
      setCategories(_categories);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Product</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill all the fields as much as possible.
        </DialogContentText>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="An easy product identifier"
                label="Product Name"
                name="productName"
                required
                variant="outlined"
                onChange={e => {
                  setShowError(false);
                  let value = e.target.value;
                  if (value) value = value.trim();
                  setProductName(value);
                }}
                defaultValue={productName}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Variant"
                name="variant"
                // required
                variant="outlined"
                onChange={e => {
                  let value = e.target.value;
                  if (value) value = value.trim();
                  setVariant(value);
                }}
                defaultValue={variant}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Size"
                name="size"
                // required
                variant="outlined"
                onChange={e => {
                  let value = e.target.value;
                  if (value) value = value.trim();
                  setSize(value);
                }}
                defaultValue={size}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="SKU"
                name="sku"
                // required
                variant="outlined"
                onChange={e => {
                  let value = e.target.value;
                  if (value) value = value.trim();
                  setSku(value);
                }}
                defaultValue={sku}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Stocks Available"
                name="stocks_available"
                type="number"
                variant="outlined"
                defaultValue={stocks}
                onChange={e => {
                  const value = e.target.value;
                  setStocks(value);
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                required
                label="Price"
                name="price"
                type="number"
                variant="outlined"
                onChange={e => {
                  const value = e.target.value;
                  setPrice(value);
                }}
                InputProps={{
                  startAdornment: <IconButton style={{ fontSize: 'inherit' }}>₱</IconButton>
                }}
                defaultValue={price}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  label="Category"
                  id="category"
                  variant="outlined"
                  value={category}
                  onChange={e => {
                    const value = e.target.value;
                    setCategory(value);
                  }}
                  defaultValue={category}
                >
                  {
                    categories.map(categoryInfo => (
                      <MenuItem key={categoryInfo.id} value={categoryInfo.name}>{categoryInfo.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Carton #"
                name="cartonNumber"
                // required
                variant="outlined"
                onChange={e => {
                  let value = e.target.value;
                  if (value) value = value.trim();
                  setCartonNumber(value);
                }}
                defaultValue={cartonNumber}
              />
            </Grid>
          </Grid>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddProduct} color="primary">
          Save
        </Button>
      </DialogActions>
      {
        showError
        && <Alert severity="error">{showError}</Alert>
      }
    </Dialog>
  );
};

EditProductDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  productEdit: PropTypes.object
};

export default EditProductDialog;
