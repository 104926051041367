import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import {pesoSymbol} from 'src/views/constants';
import Button from '@material-ui/core/Button/Button';
import PrintIcon from '@material-ui/icons/Print';
// import Grid from '@material-ui/core/Grid';

const PaymentPrompt = (props) => {
  const [change, setChange] = useState(0);
  const [amountReceived, setAmountReceived] = useState(0);
  const [discount, setDiscount] = useState(0);
  const { onClose, open, amountToPay, onCheckout } = props;
  const refInput = useRef();
  window.refInput = refInput;

  const handleClose = () => {
    setDiscount(0);
    onClose();
  };

  const amountReceivedChange = (value) => {
    value = parseFloat(value) || 0;
    setChange(value - amountToPay + discount);
    setAmountReceived(value);
  };
  const discountChange = (value) => {
    value = parseFloat(value) || 0;
    setChange(amountReceived - amountToPay + value);
    setDiscount(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xs" scroll="body">
      <DialogTitle id="simple-dialog-title" style={{textAlign: 'center'}}>Finalize Transaction</DialogTitle>
      <div
        className="fields-container"
        style={{display: 'flex',
          flexDirection: 'column',
          padding: '20px'}}
      >
        <TextField
          required
          autoFocus
          type="number"
          id="amount-received"
          label="Amount Received"
          variant="filled"
          inputProps={{ autoFocus: true }}
          inputRef={refInput}
          style={{marginBottom: '20px'}}
          onChange={event => amountReceivedChange(event.target.value)}
        />
        <TextField
          type="number"
          id="discount"
          label="Discount"
          variant="outlined"
          style={{marginBottom: '20px'}}
          onChange={event => discountChange(event.target.value)}
        />
        <div
          className="total-info"
          style={{
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginBottom: '20px'
          }}
        >
          <span>Amount To Pay: <strong>{pesoSymbol}{amountToPay}</strong></span>
          {discount ? <span>Discount: <strong>-{pesoSymbol}{discount}</strong></span> : ''}
          <span>Change: <strong>{pesoSymbol}{change}</strong></span>
        </div>
        <Button
          disabled={!amountReceived || change < 0}
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PrintIcon />}
          onClick={() => {
            onCheckout(amountReceived, discount, change);
          }}
        >
          Checkout
        </Button>
      </div>
    </Dialog>
  );
};

PaymentPrompt.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCheckout: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  amountToPay: PropTypes.number.isRequired
};

export default PaymentPrompt;