import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
// import { v4 as uuid } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import firebase from '../../../utils/firebase';

// const data = [
//   {
//     id: uuid(),
//     ref: 'CDD1049',
//     amount: 30.5,
//     customer: {
//       name: 'Ekaterina Tankova'
//     },
//     createdAt: 1555016400000,
//     status: 'pending'
//   },
//   {
//     id: uuid(),
//     ref: 'CDD1048',
//     amount: 25.1,
//     customer: {
//       name: 'Cao Yu'
//     },
//     createdAt: 1555016400000,
//     status: 'delivered'
//   },
//   {
//     id: uuid(),
//     ref: 'CDD1047',
//     amount: 10.99,
//     customer: {
//       name: 'Alexa Richardson'
//     },
//     createdAt: 1554930000000,
//     status: 'refunded'
//   },
//   {
//     id: uuid(),
//     ref: 'CDD1046',
//     amount: 96.43,
//     customer: {
//       name: 'Anje Keizer'
//     },
//     createdAt: 1554757200000,
//     status: 'pending'
//   },
//   {
//     id: uuid(),
//     ref: 'CDD1045',
//     amount: 32.54,
//     customer: {
//       name: 'Clarke Gillebert'
//     },
//     createdAt: 1554670800000,
//     status: 'delivered'
//   },
//   {
//     id: uuid(),
//     ref: 'CDD1044',
//     amount: 16.76,
//     customer: {
//       name: 'Adam Denisov'
//     },
//     createdAt: 1554670800000,
//     status: 'delivered'
//   }
// ];

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestOrders = ({ className, ...rest }) => {
  const classes = useStyles();
  // const [orders] = useState(data);
  const [lastOrders, setLastOrders] = useState([]);

  async function getProductInfo(sku) {
    return firebase.firestore().collection('products').doc(sku)
      .get()
      .then(snap => {
        const data = snap.data();
        return data;
      });
  }

  useEffect(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const unsubcribe = firebase.firestore().collection('sales').orderBy('date', 'desc').limit(6)
      .onSnapshot(async snap => {
        const _lastOrders = [];
        // const _lastOrders = snap.docs.map(x => x.data());
        const aPromises = [];
        snap.docs.forEach(doc => {
          const data = doc.data();
          const items = data.items;
          items.forEach(sku => {
            const task = getProductInfo(sku)
              .then(info => {
                const itemData = {
                  orderNumber: data.orderNumber,
                  product: `${info.name} ${info.variant} ${info.size ? info.size : ''}`,
                  ts: parseInt(data.ts, 10)
                };
                _lastOrders.push(itemData);
              });
            aPromises.push(task);
          });
        });
        await Promise.all(aPromises);
        setLastOrders(() => _lastOrders);
      });
    return () => unsubcribe();
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="Latest Orders" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Order Ref
                </TableCell>
                <TableCell>
                  Product
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lastOrders.map((order) => (
                <TableRow
                  hover
                  // key={order.orderNumber}
                  key={Math.random()}
                >
                  <TableCell>
                    {order.orderNumber}
                  </TableCell>
                  <TableCell>
                    {order.product}
                  </TableCell>
                  <TableCell>
                    {moment(parseInt(order.ts, 10)).format('DD/MM/YYYY HH:MM')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      color="primary"
                      // label={order.status}
                      label="success"
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
