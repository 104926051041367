import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Grid
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import AddProductDialog from 'src/views/products/ProductListView/AddProductDialog';
import DuplicateSkuDialog from 'src/views/products/ProductListView/DuplicateSkuDialog';
import { ExcelRenderer } from 'react-excel-renderer';
import importProducts from 'src/views/products/ProductListView/import_products';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgressWithLabel from './CircularProgressWithLabel';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, setFilter, setFilterBy, ...rest }) => {
  const classes = useStyles();
  const refFileInput = useRef();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  // const [filterBy, setFilterBy] = React.useState('product');

  const [showAddProduct, setShowProduct] = useState(false);
  const [showDuplicateSku, setShowDuplicateSku] = useState(false);

  const addProduct = () => {
    setShowProduct(true);
  };

  const fileUpload = (event) => {
    const fileObj = event.target.files[0];
    refFileInput.current.value = '';
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
        return;
      }
      console.log('resp', resp);
      const aRows = resp.rows.filter(x => x.length);
      setIsUploading(true);
      importProducts(aRows, setUploadProgress).then((result) => {
        setIsUploading(false);
        if (result && result.error) {
          setShowDuplicateSku(result.products);
        }
      });
    });
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <input ref={refFileInput} type="file" onChange={fileUpload} style={{padding: '10px', display: 'none'}} />
        {
          !isUploading &&
          <Button className={classes.importButton} onClick={() => refFileInput.current.click()}>
            Import
          </Button>
        }
        {isUploading && <CircularProgressWithLabel value={uploadProgress} />}
        {/* <Button className={classes.exportButton}>
          Export
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          onClick={addProduct}
          disabled={isUploading}
        >
          Add product
        </Button>
      </Box>
      <AddProductDialog show={showAddProduct} onClose={() => setShowProduct(false)} />
      <DuplicateSkuDialog show={showDuplicateSku} onClose={() => setShowDuplicateSku(false)} />
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  onChange={(event) => setFilter(event.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search product"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} style={{marginLeft: '20px'}}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Search by</FormLabel>
                  <RadioGroup aria-label="gender" defaultValue="product" name="gender1" onChange={event => setFilterBy(event.target.value)} row>
                    <FormControlLabel value="product" control={<Radio />} label="Product" />
                    <FormControlLabel value="sku" control={<Radio />} label="SKU" />
                    <FormControlLabel value="variant" control={<Radio />} label="Variant" />
                    <FormControlLabel value="carton" control={<Radio />} label="Carton #" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  setFilter: PropTypes.func,
  setFilterBy: PropTypes.func
};

export default Toolbar;
