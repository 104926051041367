import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import SettingsIcon from '@material-ui/icons/Settings';

const MenuComponent = ({ productInfo, onDelete, setShowEditProduct, setProductEdit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    if (typeof onDelete === 'function') onDelete();
  };
  const handleEdit = () => {
    setAnchorEl(null);
    setShowEditProduct(true);
    setProductEdit(productInfo);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </>
  );
};

MenuComponent.propTypes = {
  onDelete: PropTypes.func,
  setShowEditProduct: PropTypes.func,
  setProductEdit: PropTypes.func,
  productInfo: PropTypes.object
};

export default MenuComponent;
