import React, {
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  // Box,
  // Card,
  CardContent,
  // CardHeader,
  // Divider,
  Grid,
  // Select,
  // MenuItem,
  // FormControl,
  // InputLabel
  // makeStyles
} from '@material-ui/core';
import firebase from '../../../utils/firebase';

const EditCategoryDialog = ({ show, onClose, categoryEdit }) => {
  const [open, setOpen] = useState(false);
  const [categoryName, setCategoryName] = useState();

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleEditCategory = () => {
    if (categoryName) {
      const _categoryName = categoryName.toLowerCase().trim();
      firebase.firestore().collection('categories').doc(categoryEdit.id).set({ name: categoryName, _name: _categoryName }, { merge: true });
      setOpen(false);
      onClose();
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    if (value) value = value.trim();
    setCategoryName(value);
  };

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Category</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please fill all the fields as much as possible.
        </DialogContentText>
        {/* <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          fullWidth
        /> */}
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            {/* <Grid
              item
              md={6}
              xs={12}
            > */}
            <TextField
              fullWidth
              helperText="An easy category identifier"
              label="Category Name"
              name="categoryName"
              onChange={handleChange}
              required
              // value={values.firstName}
              defaultValue={categoryEdit.name}
              variant="outlined"
              autoFocus
              onKeyUp={(event) => {
                if (event.key === 'Enter') handleEditCategory();
              }}
            />
            {/* </Grid> */}
          </Grid>
        </CardContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleEditCategory} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditCategoryDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  categoryEdit: PropTypes.object
};

export default EditCategoryDialog;
