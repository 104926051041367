import React, { useEffect, useState, useRef } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Search as SearchIcon } from 'react-feather';
// import PrintIcon from '@material-ui/icons/Print';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AddCircleOutlineTwoToneIcon from '@material-ui/icons/AddCircleOutlineTwoTone';
import Page from 'src/components/Page';
import { pesoSymbol } from 'src/views/constants';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import firebase from '../../../utils/firebase';
import PaymentPrompt from './PaymentPrompt';
import './print.css';

const receiptline = require('receiptline');

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  // option: {
  //   fontSize: 15,
  //   '& > span': {
  //     marginLeft: 10,
  //     fontSize: 18,
  //   },
  // },
}));

const Transact = () => {
  const [products, setProducts] = useState([]);
  const classes = useStyles();
  // const addedProducts = [{name: 'NikeSB', qty: 1}];
  const [addedProducts, setAddedProducts] = useState([]);
  const [selectedItem, setSelecteditem] = useState();
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [total, setTotal] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState([]);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [orderNumber, setOrderNumber] = useState(1000);
  const refSearch = useRef();
  const refAutoComplete = useRef();
  window.addedProducts = addedProducts;
  window.products = products;

  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    limit: 50
  });

  // LATEST ORDER ID
  useEffect(() => {
    const unsub = firebase.firestore().collection('sales').orderBy('date').limitToLast(1)
      .onSnapshot(snap => {
        console.log('snap!', snap.docs);
        if (snap.empty) return;
        const doc = snap.docs[0];
        const data = doc.data();
        if (data.orderNumber) return setOrderNumber(data.orderNumber + 1);
      });
    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (window.unsubscribeProducts) {
      // console.log('unsubscribing to previous subscription to products.');
      window.unsubscribeProducts();
      window.unsubscribeProducts = '';
    }
    // console.log('subscribing products');
    window.unsubscribeProducts = firebase.firestore().collection('products').onSnapshot((snap) => {
      const _products = snap.docs.map((doc) => {
        const data = doc.data();
        return { id: doc.id, fullname: `${data.name} ${data.variant} ${data.size}`, ...doc.data() };
      });
      setProducts(_products);
    });

    return () => {
      window.unsubscribeProducts();
    };
  }, []);

  useEffect(() => {
    console.log('addedProducts', addedProducts);
    if (addedProducts.length) {
      setTotal(parseFloat(addedProducts.map(x => (x.price * x.qty)).reduce((x, a) => x + a).toFixed(2)));
    } else {
      setTotal(0);
    }
  }, [addedProducts]);

  function newTransaction() {
    setAddedProducts([]);
    setSelecteditem();
    setSelectedQuantity(1);
    setTotal(0);
    setInputValue('');
    setSelected([]);
  }

  // DEDUCTS STOCKS.
  async function checkoutDeductStocks() {
    function deductItemStocks(itemId, qty) {
      if (!qty) qty = 1;
      return firebase.firestore().collection('products').doc(itemId).update({
        stocks: firebase.firestore.FieldValue.increment(-qty)
      });
    }
    const aPromises = [];
    addedProducts.forEach((product) => {
      aPromises.push(deductItemStocks(product.id, product.qty));
    });
    return Promise.all(aPromises);
  }

  function isAlreadyAdded() {
    return !!addedProducts.filter(x => x.id === selectedItem.id).length;
  }

  function getProductAvailableQuantity(sku) {
    const a = products.filter(x => x.sku === sku);
    if (a.length) return a[0].stocks;
    return 0;
  }

  function addItem() {
    if (!selectedItem) return;
    if (isAlreadyAdded()) {
      let isAdded = false;
      addedProducts.forEach((item, index) => {
        if (item.id === selectedItem.id) {
          const qtyAfter = addedProducts[index].qty + parseInt(selectedQuantity, 10);
          if (qtyAfter <= getProductAvailableQuantity(item.sku)) {
            addedProducts[index].qty += parseInt(selectedQuantity, 10);
            isAdded = true;
          }
        }
      });
      if (isAdded) setAddedProducts(() => addedProducts);
    } else {
      setAddedProducts(prevAdded => {
        return [...prevAdded, {...selectedItem, qty: parseInt(selectedQuantity, 10)}];
      });
    }
    setSelecteditem();
    setSelectedQuantity(1);
    refSearch.current.value = '';
    setInputValue(Math.random());
  }

  function handleRowClick(event, row) {
    const name = row.id;
    console.log('Clicked!', name);
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  }

  function handleDeleteSelected() {
    const newAddedProducts = addedProducts.filter(x => !selected.includes(x.id));
    setAddedProducts(() => newAddedProducts);
    setSelected([]);
  }

  function doPrint(amountReceived, discount, change) {
    const date = new Date();
    const sDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    // const orderNumber = Math.round(Math.random() * 100000);
    // const orderNumber = orderId || 100000;
    const aProductsToPay = addedProducts.map(x => {
      return {
        item: x.fullname, qty: x.qty, price: x.price.toFixed(2), cost: (x.price * x.qty).toFixed(2)
      };
    });

    const display = {
      cpl: 48,
      encoding: 'cp437'
    };

    let printLine = `^^^"NBApparel"                        
    ^^    Ground Floor, 3M's Llauder Building,
    ^^Lluch Street, Brgy. Poblacion, Iligan City              
    ^^      Lanao del Norte 9200, Philippines      
                                                             
    |^^^Order Number:${orderNumber}|
    |^^^Date:${sDate}|
    ---------------------------------------------------------
    |^^^Qty|^^^Item|^^^Price|^^^Total|
    -----------------------------------------------------`;

    aProductsToPay.forEach(item => {
      printLine += `\n|^^${item.qty}|^^${item.item}|^^₱${item.price}|^^₱${item.cost}|`;
    });
    // |^^1|^^JORDAN DIOR GRAY,WHITE 42|^^₱1900.00|^^₱1900.00|
    // |^^1|^^NIKE SB DUNK LOW PRO DARK-GREY BLACK|^^₱2000.00|^^₱2000.00|
    // |^^1|^^JORDAN 33 BLACK,RED,YELLOW 45|^^₱1900.00|^^₱1900.00|
    printLine += `
    ---------------------------------------------------------                                                                                         
    ^^"Total Price:|"^^^"₱ ${total}"`;
    if (discount) printLine += `\n^^Discount:|^^^ -₱ ${discount.toFixed(2)}`;
    printLine += `
    ^^Amount Received:|^^^₱ ${amountReceived.toFixed(2)}
    ^^Change :|^^^₱ ${(change).toFixed(2)}
    --------------------------------                                                
                                                             
             Thank you! Please come again.
    
    `;
    const _svgContent = receiptline.transform(printLine, display);

    const newWindow = window.open('', '_blank', 'width=1000,height=1000,scrollbars=no,menubar=no,toolbar=no,location=no,status=no,titlebar=no');
    newWindow.document.open();
    newWindow.document.write(`<html><head><style></style></head><body onload="window.print()">${_svgContent}</html>`);
    newWindow.document.close();
    newWindow.print();
  }

  function closePaymentDialog() {
    setShowPaymentDialog(false);
  }

  async function logSales(amountReceived, discount, change) {
    // function getSalesId() {
    //   const d = new Date();
    //   const yr = d.getFullYear();
    //   let mo = d.getMonth() + 1;
    //   let dy = d.getDate();

    //   mo = `0${mo}`.slice(-2);
    //   dy = `0${dy}`.slice(-2);
    //   return `${yr}${mo}${dy}`;
    // }
    // const salesId = getSalesId();
    const date = new Date();
    const ts = date.getTime().toString();
    return firebase.firestore().collection('sales')
      // .doc(salesId).collection('transactions')
      .doc(ts)
      .set({
        orderNumber,
        date,
        ts,
        total,
        amountReceived,
        discount,
        change,
        items: addedProducts.map(x => x.id)
      });
  }

  function onCheckout(amountReceived, discount, change) {
    closePaymentDialog();
    checkoutDeductStocks();
    logSales(amountReceived, discount, change);
    doPrint(amountReceived, discount, change);
    newTransaction();
  }

  return (
    <Page
      className={classes.root}
      title="Transact"
    >
      <PaymentPrompt open={showPaymentDialog} onClose={closePaymentDialog} amountToPay={total || 0} onCheckout={onCheckout} />
      <Grid
        container
        direction="row"
        justify="center"
        spacing={3}
      >
        {/* <Toolbar /> */}
        <Grid item xs id="summary" className="to-print">
          <EnhancedTableToolbar numSelected={selected.length} onDelete={handleDeleteSelected} />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  <TableCell>Item Name</TableCell>
                  <TableCell align="right">Unit Price</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedProducts && addedProducts.map((row) => (
                  <TableRow
                    key={row.id}
                    onClick={(event) => handleRowClick(event, row)}
                    selected={selected.includes(row.id)}
                  >
                    <TableCell width="10px">
                      {row.qty}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name} {row.size}
                    </TableCell>
                    <TableCell align="right">
                      ₱{row.price}
                    </TableCell>
                    <TableCell align="right">
                      ₱{row.price * row.qty}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow key="total">
                  <TableCell component="th" scope="row">
                    <strong>Total</strong>
                  </TableCell>
                  <TableCell component="th" />
                  <TableCell component="th" />
                  <TableCell component="th" align="right">
                    <strong>
                      P
                      {total}
                    </strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs component={Paper}>
          <Grid
            container
            direction="row"
            justify="center"
            spacing={1}
          >
            <Grid item xs={9}>
              {/* <Grid container justify="space-between">
                <Grid item zeroMinWidth>
                  <span>HELLO THIS IS A LONG TEXT A LONG TEXT A LONG TEXT</span>
                </Grid>
                <Grid item zeroMinWidth>
                  <span>3</span>
                </Grid>
              </Grid> */}
              <Autocomplete
                ref={refAutoComplete}
                value={inputValue || ''}
                autoHighlight
                freeSolo
                id="free-solo-2-demo"
                onChange={(event, value) => {
                  if (typeof value !== 'object') return;
                  setSelecteditem(value);
                }}
                filterOptions={filterOptions}
                options={products}
                getOptionLabel={(option) => {
                  return option.sku ? `${option.sku} ${option.fullname}` : '';
                }}
                // getOptionLabel={(option) => option.fullname || ''}
                getOptionDisabled={option => {
                  if (option.stocks < 1) return true;
                  let item = addedProducts.filter(x => x.id === option.id);
                  if (item.length) {
                    item = item[0];
                    const qtyAfter = item.qty + parseInt(selectedQuantity, 10);
                    if (qtyAfter > getProductAvailableQuantity(item.sku)) {
                      return true;
                    }
                  }
                  return false;
                }}
                renderOption={(option) => (
                  <>
                    <Grid container justify="space-between">
                      <Grid item zeroMinWidth>
                        {/* <span>{`${option.name} ${option.color}`}</span> */}
                        <span>{option.sku} {option.fullname}</span>
                      </Grid>
                      {/* <Grid item zeroMinWidth>
                        <span>{option.stocks}</span>
                      </Grid> */}
                    </Grid>
                    {/* <span>{countryToFlag(option.code)}</span> */}
                    {/* { option.name } */}
                    {/* { option.stocks } */}
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    ref={refSearch}
                    label="Search item"
                    margin="normal"
                    variant="outlined"
                    onKeyDown={e => {
                      if (e.keyCode === 13 && e.target.value) {
                        addItem();
                        e.target.value = '';
                        setSelecteditem();
                      }
                    }}
                    // onChange={(event, newInputValue, reason) => {
                    //   console.log('event', event.target.value);
                    //   console.log('reason', reason);
                    //   if (reason === 'reset') {
                    //     console.log('reset');
                    //     setInputValue('');
                    //   } else {
                    //     console.log('newInputValue', newInputValue);
                    //     setInputValue(newInputValue);
                    //   }
                    // }}
                    InputProps={{
                      // type: 'search',
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon
                            fontSize="small"
                            color="action"
                          >
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      ),
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                margin="normal"
                // defaultValue={1}
                label="Qty"
                variant="outlined"
                value={selectedQuantity}
                onChange={(event) => setSelectedQuantity(event.target.value)}
                onKeyDown={e => {
                  if (e.keyCode === 13 && e.target.value) {
                    addItem();
                    e.target.value = '';
                    setSelecteditem();
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<AddCircleOutlineTwoToneIcon />}
                onClick={addItem}
              >
                Add Item
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={total <= 0}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                // startIcon={<PrintIcon />}
                // onClick={doPrint}
                onClick={() => setShowPaymentDialog(true)}
              >
                {pesoSymbol} Pay
              </Button>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <Button
                disabled={!addedProducts.length}
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<OpenInNewIcon />}
                onClick={newTransaction}
              >
                New Transaction
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Transact;
