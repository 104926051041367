import React, {
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { List, ListItem, ListItemText } from '@material-ui/core';
// import {
//   CardContent,
// } from '@material-ui/core';

// const suppliers = [
//   {
//     value: 'supplier-1',
//     label: 'Supplier 1'
//   },
//   {
//     value: 'supplier-2',
//     label: 'Supplier 2'
//   },
//   {
//     value: 'supplier-3',
//     label: 'Supplier 3'
//   }
// ];

const DuplicateSkuDialog = ({ show, onClose }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  useEffect(() => {
    if (show) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [show]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Duplicate SKU(s) Found</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Duplicate SKUs were found. Import products feature must only be used for adding new products to the database. Remove the specified SKUs from your excel file then try again.
        </DialogContentText>
        <DialogContentText>
          Duplicate SKUs:
        </DialogContentText>
        <List component="nav">
          {
            show &&
            show.map(x => (
              <ListItem>
                <ListItemText primary={x.sku} />
              </ListItem>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DuplicateSkuDialog.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default DuplicateSkuDialog;
